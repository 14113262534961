import { reducers } from 'mdt-core-components/src/reducers/index.js';

import page from './page.js';
import user from './user.js';
import title from './title.js';
import userShippingPreferences from './useraccount.js';
import notifications from './notificationsReducer.js';
import requestAccessReducer  from './requestAccessReducer.js'; 

import orderVisibilityReducer from 'mdt-core-user-components/src/reducers/orderVisibilityReducer.js';
import locationChangeReducer from 'mdt-core-components/src/reducers/locationChangeReducer.js';

// NOTE: Expose all the reducers from mdt-core-components, and add the reducers from cent
// Cent shouldn't know where the reducers from mdt-core-components are located 20190827:Alevale
export default Object.assign(reducers, {
  page,
  user,
  title,
  userShippingPreferences,
  orderVisibilityReducer,
  locationChangeReducer,
  notifications,
  requestAccessReducer
});
