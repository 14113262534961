import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switcher from './Switcher.js';
import { I18nextProvider } from 'react-i18next';
import i18n from 'mdt-core-components/src/helpers/i18n.js';
import {  MDTlayout, SnackBar, MDTDate } from 'mdt-core-components';

import MDTNavigation from 'mdt-core-components/src/components/molecules/mdt-navigation/index.js';
import { cloneDeep, get } from 'lodash';
import { themeUpdate } from 'mdt-core-components/src/actions/MeActions.js';
import { MDTApp } from 'mdt-core-components/src/actions/bootAction.js';
export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breadCrumbs: []
    };
  }

  addThemeToApp = (themeId, themesList) => {
    themesList.map((ele) => {
      document.body.classList.remove(ele.id);
    });
    document.body.classList.add(themeId);
  };

  applyTheming = () => {
    const MDTSetting = window.MDTSetting;
    const defaultTheme = MDTSetting.features.defaultTheme === '#{defaultTheme}' ? 'electric' : MDTSetting.features.defaultTheme;
    const selectedTheme = MDTSetting.themes.find((element) => (element.name === defaultTheme));
    selectedTheme?.id && this.addThemeToApp(selectedTheme.id, MDTSetting.themes);
  };

  componentDidMount() {
    const breadCrumbs = cloneDeep(
      MDTApp.getSetting('breadCrumb.pages', [], false)
    );
    this.setState({
      breadCrumbs,
    });
    this.applyTheming();
  }

  onPageClick = (page) => {
    page.link && this.props.redirectTo(page.link);
  };

  render() {
    const { page, pageType } = this.props;
    const { breadCrumbs } = this.state;

    const showBreadcrumb = MDTApp.getSetting('breadCrumb.active', false, false);
    const breadCrumbExists = !!pageType
      ? !!breadCrumbs[page + pageType]
      : !!breadCrumbs[page];

    const breadCrumbConfig = {
      breadCrumbs,
      page: pageType ? page + pageType : page,
      homeKey: 'dashboardHome',
    };

    return (
      <div>
        <div className='MDTApp notranslate'>
          <I18nextProvider i18n={i18n}>
            <MDTlayout navigation='new-navigation'>

              <MDTNavigation {...{
                breadCrumbConfig,
                breadCrumbOnPageClick: this.onPageClick
              }} />
              <div className={showBreadcrumb && breadCrumbExists
                ? 'mdt-content breadCrumbActive'
                : 'mdt-content'}>
                <Switcher />
                <SnackBar />
              </div>
            </MDTlayout>
          </I18nextProvider>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    page: get(state, 'page', ''),
    pageType: get(state, 'location.query.type', ''),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    redirectTo: (pagename) => dispatch({ type: pagename }),
    setThemeChange: (theme) => dispatch(themeUpdate(theme)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
