export default (state = 'MDT', action = {}) => {
  function translationTitle(title, defaultTitle) {
    var t = typeof window.t === 'function' ? window.t : false;
    return !!t ? t(title) : defaultTitle;
  }
  switch (action.type) {
    case 'HOME':
      return translationTitle('mdt.home', 'MDT Home');
    case 'PORTAL':
      return translationTitle('mdt.portal', 'MDT Portal');
    case 'LOGIN':
      return translationTitle('mdt.login', 'MDT Login');
    case 'ADMIN':
      return translationTitle('mdt.admin', 'MDT Admin');
    case 'REQUESTADDITIONALACCESS':
      return translationTitle(
        'mdt.requestAddAccess',
        'MDT Request Additional Access'
      );
    case 'REQUESTACCESS':
      return translationTitle('mdt.requestAccess', 'MDT Request Access');
    case 'USERPREFERENCES':
      return translationTitle('mdt.userPreferences', 'MDT User Preferences');
    case 'MANAGEACTIVECUSTOMERS':
      return translationTitle(
        'mdt.manageActiveCustomers',
        'Manage Active Customers'
      );
    case 'TERMSANDCONDITIONS':
      return translationTitle('mdt.termsAndConditions', 'Terms and Conditions');
    case 'VIEWCATALOG':
      return translationTitle('mdt.viewCatalog', 'View Catalog');
    case 'MECAPP':
      return translationTitle('mdt.mecApp', 'MEC App');
    case 'DOWNLOADCATALOG':
      return translationTitle('mdt.downloadCatalog', 'Download Catalog');
      
    default:
      return state;
  }
};
