import { getUrlParameter } from 'mdt-core-components/src/helpers/commonUtils.js';
import { MDTApp } from 'mdt-core-components/src/actions/bootAction.js';
import _ from 'lodash';

const appId = window.routeSettings.appRoute;

const routesMap = {
  HOME: {
    path: `/apps/${window.routeSettings.portalRoute}/`,
    thunk: async (dispatch, getState) => {
      const isLogin = getState().meDetails.meState.LOGGED_IN;
      let refreshToken = getUrlParameter('redirect-token') || null;
      if (!_.isNull(refreshToken)) {
        refreshToken = decodeURIComponent(refreshToken);
        sessionStorage.setItem('apiRefreshToken', refreshToken);
        MDTApp.initBootstrap();
        dispatch({ type: 'PORTAL' });
        return;
      } else {
        !isLogin ? dispatch({ type: 'LOGIN' }) : dispatch({ type: 'PORTAL' });
      }
    },
  },

  PORTAL: {
    path: `/apps/${window.routeSettings.portalRoute}/`,
    thunk: async (dispatch, getState) => {},
  },
  ACCOUNTSETUP: `/apps/${window.routeSettings.portalRoute}/accountSetup`,
  // PORTAL: '/',
  LOGIN: `/apps/${window.routeSettings.portalRoute}/login`,
  NOTIFICATIONDETAILS: `/apps/${
    window.routeSettings.portalRoute
  }/notificationDetails`,
  FORGOTPASSWORD: `/apps/${window.routeSettings.portalRoute}/forgotpassword`,
  REQUESTADDITIONALACCESS: `/apps/${
    window.routeSettings.portalRoute
  }/requestAdditionalAccess`,
  USERPREFERENCES: `/apps/${window.routeSettings.portalRoute}/userPreferences`,
  REQUESTACCESS: {
    path: `/apps/${window.routeSettings.portalRoute}/requestAccess`,
    externallyAccessible: true,
    role: 'notlogin', // + set the user's role to admin
  },
  ADMIN: {
    path: `/apps/${window.routeSettings.portalRoute}/admin`,
    thunk: async (dispatch, getState) => {},
  },
  MANAGEACTIVECUSTOMERS: `/apps/${
    window.routeSettings.portalRoute
  }/manageActiveCustomers`,
  MANAGEMYACCOUNTS: `/apps/${
    window.routeSettings.portalRoute
  }/manageMyAccounts`,
  CONTACTUS: {
    path: `/apps/${ window.routeSettings.portalRoute }/contactUs`,
    externallyAccessible: true,
  },
  TERMSANDCONDITIONS: `/apps/${
    window.routeSettings.portalRoute
  }/termsAndConditions`,
  PRODUCTDETAILS: {
    path: `/apps/${appId}/productdetails`,
    externallyAccessible: true
  },
  SEARCHPRODUCT: {
    path: `/apps/${appId}/searchProduct`,
    externallyAccessible: true
  },
  VIEWCATALOG: {
    path: `/apps/${appId}/viewCatalog`,
    externallyAccessible: true
  },
  MECAPP: {
    path: `/apps/${appId}/mecApp`,
    externallyAccessible: true
  },
  DOWNLOADCATALOG: {
    path: `/apps/${appId}/downloadCatalog`,
    externallyAccessible: true
  }

};

window.routeSettings.routesMap = routesMap

export default routesMap;
