import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {createBrowserHistory} from 'history';
import App from './components/App.js';
import configureStore from './configureStore.js';
import { MDTApp } from 'mdt-core-components/src/actions/bootAction.js';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { startListener } from 'mdt-core-components/src/listeners/history.js';
const history = createBrowserHistory();
const store = configureStore(history);
const root = createRoot(document.getElementById('root'));

// TODO: Look for a better place to init this, but this looks ok for now (testing) 20231017:Alevale
var settings = {
    buid: 'MDT',
    appid: 'portal',
    basesiteid: 'mdtb2bsite'
};

MDTApp.registerSettings(settings);

startListener(history, store);
const render = App =>
  new Promise(function(resolveStore, reject) {
      store.dispatch(MDTApp.initBootstrap(resolveStore));
  }).then(function(data) {
    root.render(
      <Provider store={store}>
        <BrowserRouter basename="/">
          <App />
        </BrowserRouter>
      </Provider>);
  }).catch((error) => {
    console.trace()
    console.log('Error while bootstrapping', error);
    console.trace(error)
  });

render(App);
