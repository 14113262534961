import { get } from 'lodash';

const initState = {
    notificationPreferences: { configuration: [] },
    enabledNotificationPreferences: {},
    originalEnabledNotificationPreferences: {},
    supportedSuppliers: [],
    suppliers: [],
    customersListData: {},
    // NOTE: This will hold the state of the selected entities in the FE, it will have the following structure:
    // {
    //     'soldTo#1': {
    //         'supplier#1': {
    //             payers: ['payer#1', 'payer#2'],
    //             shipTos: ['shipTo#1', 'shipTo#2']
    //         }
    //     }
    // }
    // 20220704:Alevale
    selectedEntities: {},
    originalSelectedEntities: {},
}

const SET_NOTIFICATION_PREFERENCES = 'SET_NOTIFICATION_PREFERENCES'
const SET_ENABLED_NOTIFICATION_PREFERENCES = 'SET_ENABLED_NOTIFICATION_PREFERENCES'
const SET_ORIGINAL_ENABLED_NOTIFICATION_PREFERENCES = 'SET_ORIGINAL_ENABLED_NOTIFICATION_PREFERENCES'
const SET_SUPPORTED_SUPPLIERS = 'SET_SUPPORTED_SUPPLIERS'
const SET_CUSTOMERS_LIST_DATA = 'SET_CUSTOMERS_LIST_DATA'
const SET_SELECTED_ENTITIES = 'SET_SELECTED_ENTITIES'
const SET_ORIGINAL_SELECTED_ENTITIES = 'SET_ORIGINAL_SELECTED_ENTITIES'
const ADD_SELECTED_ENTITY = 'ADD_SELECTED_ENTITY'
const REMOVE_SELECTED_ENTITY = 'REMOVE_SELECTED_ENTITY'
const RESET_PARTNERS = 'RESET_PARTNERS'
const REMOVE_ALL_SELECTED_ENTITIES = 'REMOVE_ALL_SELECTED_ENTITIES'

const ADD_ALL_FROM_SOLD_TO = 'ADD_ALL_FROM_SOLD_TO'
const REMOVE_ALL_FROM_SOLD_TO = 'REMOVE_ALL_FROM_SOLD_TO'

export const setNotificationPreferences = (data) => ({ type: SET_NOTIFICATION_PREFERENCES, payload: data })
export const setEnabledNotificationPreferences = (data) => ({ type: SET_ENABLED_NOTIFICATION_PREFERENCES, payload: data })
export const setOriginalEnabledNotificationPreferences = (data) => ({ type: SET_ORIGINAL_ENABLED_NOTIFICATION_PREFERENCES, payload: data })
export const setSupportedSuppliers = (data) => ({ type: SET_SUPPORTED_SUPPLIERS, payload: data })
export const setCustomersListData = (data) => ({ type: SET_CUSTOMERS_LIST_DATA, payload: data })

export const setSelectedEntities = (data) => ({ type: SET_SELECTED_ENTITIES, payload: data })
export const setOriginalSelectedEntities = (data) => ({ type: SET_ORIGINAL_SELECTED_ENTITIES, payload: data })
export const addSelectedEntity = (data) => ({ type: ADD_SELECTED_ENTITY, payload: data })
export const removeSelectedEntity = (data) => ({ type: REMOVE_SELECTED_ENTITY, payload: data })
export const resetPartners = (data) => ({ type: RESET_PARTNERS, payload: data })
export const removeAllSelectedEntities = (data) => ({ type: REMOVE_ALL_SELECTED_ENTITIES, payload: data })

export const addAllFromSoldTo = (data) => ({ type: ADD_ALL_FROM_SOLD_TO, payload: data })
export const removeAllFromSoldTo = (data) => ({ type: REMOVE_ALL_FROM_SOLD_TO, payload: data })

const extractDataFromPartnerList = (partners, supportedSuppliers) => {
    return partners.map(partner => {
        const supplier = supportedSuppliers.find(supplierID => partner.includes(supplierID))
        if (!supplier) {
            throw Error(`Invalid partner: ${ partner }, must be one of: ${ supportedSuppliers.join(', ') }`)
        }
        const rest = partner.replace(`${supplier}_`, '')

        const [soldTo, payer, shipTo] = rest.split('_')
        if (!soldTo || !payer || !shipTo) {
            throw Error(`Invalid partner composition: ${ partner }, missing one of the following: soldTo, payer, shipTo`)
        }

        return {
            supplier,
            soldTo,
            payer,
            shipTo
        }
    }).filter(partner => !!partner);
}

const computeAllSelectedEntities = (state, partners, original) => {
    const partnerDataList = extractDataFromPartnerList(partners, state.supportedSuppliers)
    const selectedEntities = partnerDataList.reduce((selectedEntities, partnerData) => {
        const { soldTo, supplier, payer, shipTo } = partnerData
        if (!selectedEntities[soldTo]) {
            selectedEntities[soldTo] = {}
        }
        if (!selectedEntities[soldTo][supplier]) {
            selectedEntities[soldTo][supplier] = {}
        }
        if (!selectedEntities[soldTo][supplier].payer) {
            selectedEntities[soldTo][supplier].payer = []
        }
        if (!selectedEntities[soldTo][supplier].shipTo) {
            selectedEntities[soldTo][supplier].shipTo = []
        }
        // NOTE: This should appear only once on the array, so verify if it doesn't exist before adding it 20220704:Alevale
        if (selectedEntities[soldTo][supplier].payer.indexOf(payer) === -1) {
            selectedEntities[soldTo][supplier].payer.push(payer)
        }
        if (selectedEntities[soldTo][supplier].shipTo.indexOf(shipTo) === -1) {
            selectedEntities[soldTo][supplier].shipTo.push(shipTo)
        }

        return selectedEntities
    }, {})
    if (original) {
        return { ...state, originalSelectedEntities: selectedEntities }
    }
    return { ...state, selectedEntities }
}

const validateEntityType = (type) => {
    // NOTE: Enforce the type to be the sames as the ones we have in the JSON object 20220704:Alevale
    if (!['payer', 'shipTo'].includes(type)) {
        throw Error(`Invalid type: ${type}, must be one of: payer, shipTo`)
    }
}

const computeAddSelectedEntity = (state, { soldTo, supplier, entityData, type }) => {
    validateEntityType(type)
    const selectedEntities = { ...state.selectedEntities }
    // NOTE: Check if soldTos and suppliers exist and if so add to the array 20220704:Alevale
    if (!selectedEntities[soldTo]) {
        selectedEntities[soldTo] = {}
    }
    if (!selectedEntities[soldTo][supplier]) {
        selectedEntities[soldTo][supplier] = {}
    }
    if (!selectedEntities[soldTo][supplier][type]) {
        selectedEntities[soldTo][supplier][type] = []
    }
    // NOTE: This should appear only once on the array, so verify if it doesn't exist before adding it 20220704:Alevale
    if (selectedEntities[soldTo][supplier][type].indexOf(entityData) === -1) {
        selectedEntities[soldTo][supplier][type].push(entityData)
    }

    return { ...state, selectedEntities }
}

const computeRemoveSelectedEntity = (state, { soldTo, supplier, entityData, type }) => {
    validateEntityType(type)
    const selectedEntities = { ...state.selectedEntities }
    // NOTE: Check if soldTos and suppliers exist and if so add to the array 20220704:Alevale
    if (!selectedEntities[soldTo]) {
        selectedEntities[soldTo] = {}
    }
    if (!selectedEntities[soldTo][supplier]) {
        selectedEntities[soldTo][supplier] = {}
    }
    if (!selectedEntities[soldTo][supplier][type]) {
        selectedEntities[soldTo][supplier][type] = []
    }
    const index = selectedEntities[soldTo][supplier][type].indexOf(entityData)
    if (index > -1) {
        selectedEntities[soldTo][supplier][type].splice(index, 1)
    }
    return { ...state, selectedEntities }
}

const computeAddAllFromSoldTo = (state, soldTo) => {
const selectedEntities = { ...state.selectedEntities }
    if (!selectedEntities[soldTo]) {
        selectedEntities[soldTo] = {}
    }
    const customersListData = state.customersListData

    customersListData?.customerList?.forEach((customer, i) => {
            const soldToID = get(customer, 'soldTo.id');
            if (soldToID === soldTo) {
                // NOTE: Go through all of the suppliers for this customer and
                // activate all it's payers and shipTos 20220704:Alevale
                customer?.supplierCustomerContract?.forEach((supplierContract, index) => {
                    const supplier = get(supplierContract, 'supplierOrgDetails.id')
                    selectedEntities[soldTo][supplier] = {}
                    if (!selectedEntities[soldTo][supplier].payer) {
                        selectedEntities[soldTo][supplier].payer = []
                    }
                    if (!selectedEntities[soldTo][supplier].shipTo) {
                        selectedEntities[soldTo][supplier].shipTo = []
                    }


                    supplierContract?.payers?.forEach((payer) => {
                        if (selectedEntities[soldTo][supplier].payer.indexOf(payer.id) === -1) {
                            selectedEntities[soldTo][supplier].payer.push(payer.id)
                        }
                    })

                    supplierContract?.shipTos?.forEach((shipTo) => {
                        if (selectedEntities[soldTo][supplier].shipTo.indexOf(shipTo.id) === -1) {
                            selectedEntities[soldTo][supplier].shipTo.push(shipTo.id)
                        }
                    })
                })
            }
    })

    return { ...state, selectedEntities }
}

const computeRemoveAllFromSoldTo = (state, soldTo) => {
    const selectedEntities = { ...state.selectedEntities }

    if (!selectedEntities[soldTo]) {
        throw Error(`Invalid soldTo: ${soldTo}, must be one of: ${Object.keys(selectedEntities).join(', ')}`)
    }

    selectedEntities[soldTo] = {}

    return { ...state, selectedEntities }
}

export default (state = initState, action) => {
    switch (action.type) {
        case SET_NOTIFICATION_PREFERENCES:
            return { ...state, notificationPreferences: action.payload };
        case SET_ENABLED_NOTIFICATION_PREFERENCES:
            return { ...state, enabledNotificationPreferences: action.payload };
        case SET_ORIGINAL_ENABLED_NOTIFICATION_PREFERENCES:
            return { ...state, originalEnabledNotificationPreferences: action.payload };
        case SET_SUPPORTED_SUPPLIERS:
            return { ...state, supportedSuppliers: action.payload };
        case SET_CUSTOMERS_LIST_DATA:
            return { ...state, customersListData: action.payload };
        case RESET_PARTNERS:
            return { ...state, selectedEntities: { ...state.originalSelectedEntities } };
        case REMOVE_ALL_SELECTED_ENTITIES:
            return { ...state, selectedEntities: {} };
        case SET_SELECTED_ENTITIES:
            return computeAllSelectedEntities(state, action.payload);
        case SET_ORIGINAL_SELECTED_ENTITIES:
            return computeAllSelectedEntities(state, action.payload, true);
        case ADD_SELECTED_ENTITY:
            return computeAddSelectedEntity(state, action.payload);
        case REMOVE_SELECTED_ENTITY:
            return computeRemoveSelectedEntity(state, action.payload);
        case ADD_ALL_FROM_SOLD_TO:
            return computeAddAllFromSoldTo(state, action.payload);
        case REMOVE_ALL_FROM_SOLD_TO:
            return computeRemoveAllFromSoldTo(state, action.payload);
        default:
            return state
    }
}
